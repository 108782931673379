import React from "react";
import "./App.css";
import head from "./head.png"
import backgroundShape from "./b3.svg"
import linkedin from "./l.png"

function App() {
  return (
    <div className="portfolio">
      <div className="portfolio-text">
        <h1>
          Hello, and Welcome to my page. <br />
          My name is{" "}
          <span className="highlight-text">Silvan Kisseleff</span>
        </h1>
        <p>
          To get in contact with me, write me a message on Linkedin.
        </p>
        <a href="https://www.linkedin.com/in/silvan-kisseleff-a38a2b179">
          <img style={{
              marginTop: 20,
                alignSelf: "center",
                display: "inherit"
          }} width="40" src={linkedin} alt="linkedin" />
        </a>
      </div>
      <div className="portfolio-image">
      <div
          className="image-shape"
          style={{ backgroundImage: `url(${backgroundShape})` }}
        >
          <img src={head} width="250" height="250" className="image" alt="profile" />
        </div>
        
      </div>
    </div>
  );
}

export default App;
